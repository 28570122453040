import React, { useContext, useEffect, useState } from "react";

import { ButtonGroup } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Link, navigate } from "gatsby";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import Flex from "~/components/Containers/Flex";
import { SectionHeader, SpacedPaper } from "~/components/Containers/SpacedPaper";
import LoadingError from "~/components/Loaders/LoadingError";
import ActionReceiverComponent from "~/components/Nurses/ActionReceiverComponent";
import NurseData from "~/components/Nurses/NurseData";
import { FiltersContext } from "~/components/Stores/FilterStore";
import DisplayBlockedTimeBlock from "~/components/Timeblocks/DisplayBlockedTimeBlock";
import DisplayTimeBlock from "~/components/Timeblocks/DisplayTimeBlock";
import Bold from "~/components/Typography/Bold";
import nurseService from "~/utils/api/v1/nurseService";
import timeBlockService from "~/utils/api/v1/timeblockService";
import { nurseAPI } from "~/utils/api/v2";
import type { Laboratory } from "~/utils/interfaces/Laboratory";
import type { Nurse, Specialty } from "~/utils/interfaces/Nurse";
import { GroupedBlockedTimeBlocks, GroupedTimeBlocks } from "~/utils/interfaces/Timeblock";
import { groupBlockedTimeBlocks, groupTimeBlocks } from "~/utils/timeblocks/group";

interface Props {
  id: string;
}

const NurseProfile = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>({});
  const [nurseData, setNurseData] = useState<Nurse | undefined>();
  const [activeTimeBlocks, setActiveTimeBlocks] = useState<GroupedTimeBlocks>({});
  const [nurseLabs, setNurseLabs] = useState<Laboratory[]>([]);
  const [servicesCategories, setServicesCategories] = useState<Array<string>>([]);
  const [blockedTimeBlocks, setBlockedTimeBlocks] = useState<GroupedBlockedTimeBlocks>({});
  // @ts-expect-error Type 'Filters' must have a '[Symbol.iterator]()' method that returns an iterator.ts(2488)
  const [, filtersDispatch] = useContext(FiltersContext);

  const fetchData = async (): Promise<void> => {
    setLoading(true);
    try {
      const reqs = await Promise.all([
        nurseService.fetchNurseProfile(props.id),
        timeBlockService.fetchNurseTimeBlocks(props.id),
        nurseAPI.fetchNurseLabs(props.id),
        nurseService.fetchServicesCategories(props.id),
      ]);
      const data = reqs[0].data;
      data.specialties = data.specialties.map((speciality: Specialty) => speciality.name);
      setNurseData(data);
      filtersDispatch({
        type: "UPDATE_NURSE",
        payload: reqs[0].data.id,
      });
      setActiveTimeBlocks(groupTimeBlocks(reqs[1].data.data.timeblocks));
      setBlockedTimeBlocks(groupBlockedTimeBlocks(reqs[1].data.data.blocked) as GroupedBlockedTimeBlocks);
      setNurseLabs(reqs[2] || []);
      setServicesCategories(reqs[3].data.data);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const updateSpecialties = (newValues: string[]) => {
    setNurseData((oldValues) => ({
      ...oldValues,
      specialties: newValues,
    }));
  };

  const handleActivate = async () => {
    setLoading(true);
    try {
      const data = {
        active: !nurseData.active,
      };
      await nurseService.activateNurse(nurseData.id, data);
      setNurseData((oldValues) => ({
        ...oldValues,
        active: !oldValues.active,
      }));
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const updateNurse = async (): Promise<void> => {
    try {
      if (!nurseData) return;
      const data = {
        fingerprint_available: nurseData.fingerprint_available,
        specialties: nurseData.specialties,
        fake: nurseData.fake,
      };
      const req = await nurseService.updateNurse(nurseData.id, data);
      const nurseInfo = req.data;
      nurseInfo.specialties = nurseInfo.specialties.map((speciality: Specialty) => speciality.name);
      setNurseData(nurseInfo);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PrivateRoute>
      <LoadingError
        loading={loading}
        error={error}
      />
      {!loading && nurseData && (
        <>
          <NurseData
            nurseData={nurseData}
            setNurseData={setNurseData}
            updateNurse={updateNurse}
            updateSpecialties={updateSpecialties}
            handleActivate={handleActivate}
            nurseLabs={nurseLabs}
            servicesCategories={servicesCategories}
          />
          <SpacedPaper variant="outlined">
            <Flex direction="column">
              <SectionHeader>
                <Bold>Horarios habilitados</Bold>
                <ButtonGroup variant="text">
                  <Button
                    color="primary"
                    onClick={async () => navigate("/dashboard/")}
                  >
                    Ver agenda
                  </Button>
                  <Link
                    to={`/health-team/${nurseData.id}/schedule/`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button color="primary">Editar</Button>
                  </Link>
                </ButtonGroup>
              </SectionHeader>
              <hr />
              <DisplayTimeBlock timeblocks={activeTimeBlocks} />
            </Flex>
          </SpacedPaper>
          <SpacedPaper variant="outlined">
            <Flex direction="column">
              <SectionHeader>
                <Bold>Horarios bloqueados</Bold>
                <Link
                  to={`/health-team/${nurseData.id}/block-schedule/`}
                  style={{ textDecoration: "none" }}
                >
                  <Button color="primary">Editar</Button>
                </Link>
              </SectionHeader>
              <hr />
              <DisplayBlockedTimeBlock timeblocks={blockedTimeBlocks} />
            </Flex>
          </SpacedPaper>
          <ActionReceiverComponent
            nurseData={nurseData}
            loading={loading}
            setError={setError}
          />
        </>
      )}
    </PrivateRoute>
  );
};

export default NurseProfile;
