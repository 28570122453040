import React, { useEffect, useState } from "react";

// other files

// components
import { Button, ButtonGroup, FormControlLabel } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import { MenuProps as MenuPropsType } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import Swal from "sweetalert2";

import Flex from "~/components/Containers/Flex";
import { SectionHeader, SpacedPaper } from "~/components/Containers/SpacedPaper";
import Bold from "~/components/Typography/Bold";
import nurseService from "~/utils/api/v1/nurseService";
import { COUNTRIES } from "~/utils/data/constants";
import type { Laboratory } from "~/utils/interfaces/Laboratory";
import type { Nurse, Specialty } from "~/utils/interfaces/Nurse";

const BigAvatar = styled(Avatar)`
  width: 10rem;
  height: 10rem;
`;

const SpacedSpan = styled.span`
  padding: 0.5rem;
`;

const FlexContainer = styled(Flex)`
  overflow: auto;
  max-width: 400px;

  .MuiChip-root {
    margin: 0.25rem;
  }
`;

const UserDataContainer = styled.div`
  display: grid;
  grid-template-columns: 6rem 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
`;

const locationSelectProps: Partial<MenuPropsType> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

interface Props {
  nurseData: Nurse;
  nurseLabs: Laboratory[];
  servicesCategories: Array<string>;
  setNurseData: (nurseData: Nurse) => void;
  updateSpecialties: (newValues: string[]) => void;
  updateNurse: () => Promise<void>;
  handleActivate: () => void;
}

const NurseData = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(false);
  const [nurseInitialData, setNurseInitialData] = useState<Nurse>();
  const [validPhotoUploaded, setValidPhotoUploaded] = useState<boolean>(false);
  const [country, setCountry] = useState<string>("Chile");
  const [specialtyOptions, setSpecialtyOptions] = useState<string[]>([]);
  const [files, setFiles] = useState([]);

  const traductions = {
    blood: "Sangre",
    urine: "Orina",
    vaccine: "Vacuna",
    covid: "Covid",
    kine: "Kine",
    electrocardiogram: "ECG",
  };

  useEffect(() => {
    const fetchNurseSpecialties = async () => {
      const res = await nurseService.fetchNurseSpecialties();
      const specialties = res.data.map((speciality: Specialty) => speciality.name);
      setSpecialtyOptions(specialties);
    };
    fetchNurseSpecialties();
    setNurseInitialData(props.nurseData);
  }, []);

  const updateNurse = async () => {
    setLoading(true);
    await props.updateNurse();
    setEditable(false);
    setLoading(false);
  };

  const handleChange = (event: any) => {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];

      // if file > 15MB, reject
      if (file.size > 15_000_000) {
        Swal.fire({
          icon: "error",
          title: "El archivo no debe pesar mas de 15MB",
        });
        setValidPhotoUploaded(false);
        return false;
      }
      // add file to files state
      setValidPhotoUploaded(true);
      setFiles((prevFiles) => {
        return [...prevFiles, file];
      });
    }
  };

  const renderSelectedOptions = (selected: string[] | string | any) => {
    return (
      <Flex wrap="wrap">
        {(selected as string[]).map((value) => (
          <Chip
            variant="outlined"
            color="primary"
            key={value}
            label={value}
          />
        ))}
      </Flex>
    );
  };

  const uploadProfilePic = async (): Promise<void> => {
    setLoading(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    const uploaded = await nurseService.uploadProfilePicture(props.nurseData.id, formData);
    if (uploaded) {
      Swal.fire({
        icon: "success",
        title: "Foto de perfil cambiada",
      });
      setLoading(false);
      setEditable(false);
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "La foto no se pudo subir",
      });
    }
  };

  const handleActivate = async () => {
    const check = await Swal.fire({
      title: "¿Estás seguro?",
      icon: "warning",
      showCancelButton: true,
    });
    if (!check.isConfirmed) {
      return;
    }
    props.handleActivate();
  };

  const handleCancel = () => {
    if (!nurseInitialData) return;
    props.setNurseData(nurseInitialData);
    setEditable(false);
  };

  const countrySelector = (): JSX.Element => {
    return (
      <FormControl fullWidth>
        {/* @ts-expect-error */}
        <Select
          fullWidth
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        >
          {COUNTRIES.map((name) => (
            <MenuItem
              value={name}
              key={name}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>País</FormHelperText>
      </FormControl>
    );
  };

  const editableSelectors = (): JSX.Element => {
    return (
      <>
        {countrySelector()}
        {/* specialties */}
        <FormControl fullWidth>
          <Select
            multiple
            fullWidth
            value={props.nurseData.specialties}
            onChange={(event) => {
              props.updateSpecialties(event.target.value as string[]);
            }}
            input={<Input id="select-multiple-chip" />}
            MenuProps={locationSelectProps}
            renderValue={renderSelectedOptions}
          >
            {specialtyOptions?.map((specialty) => (
              <MenuItem
                key={specialty}
                value={specialty}
              >
                {specialty}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Especialidades</FormHelperText>
        </FormControl>
      </>
    );
  };

  return (
    <SpacedPaper variant="outlined">
      <Flex direction="column">
        <SectionHeader>
          <Bold>Datos personales</Bold>
          {editable ? (
            <ButtonGroup variant="text">
              <Button
                color="secondary"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={updateNurse}
              >
                Actualizar
              </Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup variant="text">
              {props.nurseData.active ? (
                <Button
                  color="secondary"
                  onClick={handleActivate}
                >
                  Desactivar
                </Button>
              ) : (
                <Button
                  color="primary"
                  onClick={handleActivate}
                >
                  Activar
                </Button>
              )}
              <Button
                color="primary"
                onClick={() => {
                  setEditable(true);
                }}
              >
                Editar
              </Button>
              <Button
                color="primary"
                onClick={async () =>
                  navigate(`/health-team/${props.nurseData.id}/exams/`, {
                    state: props.nurseData,
                  })
                }
              >
                Detalle exámenes
              </Button>
            </ButtonGroup>
          )}
        </SectionHeader>
        <hr />
        {loading ? (
          <Flex
            justify="center"
            align="center"
            padding="3rem"
          >
            <CircularProgress />
          </Flex>
        ) : (
          <Flex
            padding="2rem"
            justify="space-between"
          >
            <Flex
              direction="column"
              justify="center"
            >
              {editable ? (
                <Flex direction="column">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleChange}
                  />
                  <Button
                    style={{ marginTop: 10 }}
                    color="primary"
                    variant="contained"
                    disabled={!validPhotoUploaded}
                    onClick={uploadProfilePic}
                  >
                    Subir Foto de Perfil
                  </Button>
                </Flex>
              ) : (
                <BigAvatar src={props.nurseData.profile_picture} />
              )}
            </Flex>
            <UserDataContainer>
              <Bold>Nombre: </Bold>
              {props.nurseData.names} {props.nurseData.last_names}
              <Bold>Email: </Bold>
              {props.nurseData.email}
              {props.nurseData.rut !== "" && <Bold>Rut: </Bold>}
              {props.nurseData.rut}
              <Bold>Teléfono: </Bold>
              {props.nurseData.phone}
            </UserDataContainer>
            <Flex
              direction="column"
              align="center"
            >
              <FormControlLabel
                control={
                  <Switch
                    disabled={!editable}
                    name="¿Tiene huellero?"
                    color="primary"
                    checked={props.nurseData.fingerprint_available}
                    onChange={(e) => {
                      props.setNurseData({
                        ...props.nurseData,
                        fingerprint_available: e.target.checked,
                      });
                    }}
                  />
                }
                label="¿Tiene huellero?"
              />
              <FormControlLabel
                control={
                  <Switch
                    disabled={!editable}
                    name="Cuenta Falsa"
                    color="primary"
                    checked={props.nurseData.fake}
                    onChange={(e) => {
                      props.setNurseData({
                        ...props.nurseData,
                        fake: e.target.checked,
                      });
                    }}
                  />
                }
                label="Cuenta Falsa"
              />
              <Bold>Servicios:</Bold>
              <FlexContainer
                justify="center"
                align="center"
                padding="1rem 0rem"
                wrap="wrap"
              >
                {props.servicesCategories.map((category) => (
                  <Chip
                    variant="outlined"
                    color="primary"
                    key={category}
                    label={traductions[category]}
                  />
                ))}
              </FlexContainer>
              <Bold>Sectores:</Bold>
              <Link to="/sectors">Ir a sectores</Link>
              <Button></Button>
              {editable && (
                <FlexContainer
                  justify="center"
                  align="center"
                  padding="1rem 0rem"
                  wrap="wrap"
                >
                  {editableSelectors()}
                </FlexContainer>
              )}
              {!editable && (
                <>
                  <Bold>Especialidades:</Bold>
                  {props.nurseData.specialties?.length == 0 && "No tiene especialidades"}
                  {props.nurseData.specialties?.length > 0 && (
                    <FlexContainer>
                      {props.nurseData.specialties.map((speciality: string) => (
                        <Chip
                          variant="outlined"
                          color="primary"
                          key={speciality}
                          label={speciality}
                        />
                      ))}
                    </FlexContainer>
                  )}
                </>
              )}
              <SpacedSpan>
                <Bold>Laboratorios:</Bold>
                {props.nurseLabs.map((lab) => (
                  <p>{lab.display_name}</p>
                ))}
              </SpacedSpan>
            </Flex>
          </Flex>
        )}
      </Flex>
    </SpacedPaper>
  );
};

export default NurseData;
